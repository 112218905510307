
import React from 'react';

function Profile() {
  return (
    <div class="w-800 grid-8 l-grid-6 m-grid-4 s-grid-3 gap-1rem">
      p
  </div>
  );
}

export default Profile;