const awardsData = [
    {
      title: "クレオフーガ　クリスマスソングコンテスト　歌もの（パーティー系）部門　優秀賞",
      year: 2010,
      month: 11,
      date: 1,
      description: "株式会社クレオフーガ（現：株式会社オーディオストック）が運営する作曲コンテストサイト「クレオフーガ」で開催された「クリスマスソングコンテスト」にて、優秀賞をいただきました。"
    },
    {
      title: "クレオフーガ　第二回スマートフォン着信音コンテスト　目覚まし音部門　採用",
      year: 2012,
      month: 2,
      date: 14,
      description: "株式会社クレオフーガ（現：株式会社オーディオストック）が運営する作曲コンテストサイト「クレオフーガ」で開催された「第二回スマートフォン着信音コンテスト」にて、優秀作品として採用されました。採用作品は、iOS/Andoroid対応の着信音アプリ「爽快目覚し音」に収録されました。"
    },
    {
      title: "クレオフーガ　ネコンテスト～猫がテーマのコンテスト～　優秀賞",
      year: 2012,
      month: 5,
      date: 29,
      description: "株式会社クレオフーガ（現：株式会社オーディオストック）が運営する作曲コンテストサイト「クレオフーガ」で開催された「ネコンテスト～猫がテーマのコンテスト～」にて、優秀賞をいただきました。"
    },
    {
      title: "学生作曲家選手権2013　ボーカルの入った楽曲部門　佳作",
      year: 2013,
      month: 2,
      date: 3,
      description: "JuCuStageが主催するコンテスト「学生作曲家選手権2013」にて、佳作に選ばれました。"
    },
    {
      title: "クレオフーガ　第4回スマートフォン着信音コンテスト　採用",
      year: 2014,
      month: 7,
      date: 10,
      description: "株式会社クレオフーガ（現：株式会社オーディオストック）が運営する作曲コンテストサイト「クレオフーガ」で開催された「第4回スマートフォン着信音コンテスト」にて、優秀作品として採用されました。採用作品は、iOS/Andoroid対応の着信音アプリ「オルゴール着信音」に収録されました。"
    },
    {
      title: "300イイネでカラオケデビューコンテスト　365票獲得",
      year: 2014,
      month: 7,
      date: 10,
      description: "株式会社クレオフーガ（現：株式会社オーディオストック）が運営する作曲コンテストサイト「クレオフーガ」で開催された「300イイネでカラオケデビューコンテスト」にて、365票を獲得しました。300票以上獲得作品は、全国のカラオケの鉄人で配信されました。"
    },
    {
      title: "綺和美（KIWABI）　この秋に結婚式をむかえる30代半ばの女性におくる言葉　採用",
      year: 2014,
      month: 9,
      date: 24,
      description: "CrowdWorksで開催された、スリーエム株式会社主催コンペ「<綺和美 KIWABI> のヘアケア製品をつかって欲しい女性におくるコピーライティング」にて、採用されました。"
    },
    {
      title: "アッコの宇宙大戦争大喜利　金賞",
      year: 2014,
      month: 9,
      date: 24,
      description: "Twitter連動キャンペーン「アッコ大喜利キャンペーン」のお題「あなたの考えた最強のアッコは？」にて、金賞をいただきました。アイデアは、株式会社GNTと株式会社ホリプロが共同リリースしたスマートフォンアプリ「アッコの宇宙大戦争」の公式キャラクターとして起用されました。"
    },
    {
      title: "第10回あなたが選ぶオタク川柳大賞　入選",
      year: 2015,
      month: 3,
      date: 26,
      description: "株式会社インターリンクが開催する「第10回あなたが選ぶオタク川柳大賞」にて、入選しました。"
    },
    {
      title: "おすすめ同人紹介による同人ゲーム・オブ・ザ・イヤー2015　作曲部門賞　ノミネート",
      year: 2016,
      month: 6,
      date: 11,
      description: "みなみさん主催の「おすすめ同人紹介による同人ゲーム・オブ・ザ・イヤー2015」にて、楽曲提供させていただいたゲーム作品が作曲部門賞にノミネートされました。"
    },
    {
      title: "おすすめ同人紹介による同人ゲーム・オブ・ザ・イヤー2015　歌曲部門賞",
      year: 2016,
      month: 6,
      date: 11,
      description: "みなみさん主催の「おすすめ同人紹介による同人ゲーム・オブ・ザ・イヤー2015」にて、楽曲提供させていただいたゲーム作品が歌曲部門賞をいただきました。"
    },
    {
      title: "第105回パピプペポ川柳コンテスト　入選",
      year: 2017,
      month: 7,
      date: 27,
      description: "株式会社リアリックスが開催する「第105回パピプペポ川柳コンテスト」にて、入選しました。"
    },
    {
      title: "口語詩句投稿サイト72h　2020年度　月間投稿佳作",
      year: 2020,
      month: null,
      date: null,
      description: "公益財団法人佐々木泰樹育英会が運営するサイト「口語詩句投稿サイト72h」にて、2020年度月間投稿佳作に44作品選ばれました。"
    },
    {
      title: "口語詩句投稿サイト72h　2021年度　月間投稿佳作",
      year: 2021,
      month: null,
      date: null,
      description: "公益財団法人佐々木泰樹育英会が運営するサイト「口語詩句投稿サイト72h」にて、2021年度月間投稿佳作に4作品選ばれました。"
    },
    {
      title: "口語詩句投稿サイト72h　2022年度　月間投稿佳作",
      year: 2022,
      month: null,
      date: null,
      description: "公益財団法人佐々木泰樹育英会が運営するサイト「口語詩句投稿サイト72h」にて、2022年度月間投稿佳作に4作品選ばれました。"
    },
    {
      title: "口語詩句投稿サイト72h　2023年度　月間投稿佳作",
      year: 2023,
      month: null,
      date: null,
      description: "公益財団法人佐々木泰樹育英会が運営するサイト「口語詩句投稿サイト72h」にて、2023年度月間投稿佳作に4作品選ばれました。"
    },
  ];
  
  // IDが存在しない場合に自動採番
  const awardsWithIds = awardsData.map((award, index) => ({
    ...award,
    id: award.id || index + 1, // 既にIDがあればそれを使用、なければ自動採番
  }));
  const sortedAwardsData = awardsWithIds.sort((a, b) => b.id - a.id);
  export default sortedAwardsData;
  