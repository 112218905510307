const certificationsData = [
    {
      title: "普通自動車第一種運転免許",
      year: 2014,
      month: 4,
      date: 10,
      description: "",
      isValid: 1,
      isPublic: 1,
    },
    {
      title: "Oracle Certified Java Programmer, Bronze SE 7/8",
      year: 2016,
      month: 2,
      date: 1,
      description: "",
      isValid: 1,
      isPublic: 0,
    },
    {
      title: "Google デジタルワークショップ「デジタル マーケティングの基礎」認定資格試験",
      year: 2017,
      month: 12,
      date: 1,
      description: "",
      isValid: 1,
      isPublic: 0,
    },
    {
      title: "一ツ星タマリエ検定 ブロンズランク",
      year: 2022,
      month: 12,
      date: 23,
      description: "",
      isValid: 1,
      isPublic: 0,
    },
    {
      title: "一ツ星タマリエ検定 シルバーランク",
      year: 2022,
      month: 12,
      date: 25,
      description: "",
      isValid: 1,
      isPublic: 0,
    },
    {
      title: "一ツ星タマリエ検定 ゴールドランク",
      year: 2022,
      month: 12,
      date: 27,
      description: "",
      isValid: 1,
      isPublic: 0,
    },
    {
      title: "LINE Green Badge LINE公式アカウント Basic",
      year: 2023,
      month: 1,
      date: 1,
      description: "",
      isValid: 0,
      isPublic: 0,
    },
    {
      title: "日本漢字能力検定8級",
      year: 2024,
      month: 7,
      date: 16,
      description: "",
      isValid: 0,
      isPublic: 0,
      description: "未取得、勉強中",
    },
    {
      title: "実用数学技能検定8級",
      year: 2024,
      month: 7,
      date: 16,
      description: "",
      isValid: 0,
      isPublic: 0,
      description: "未取得、勉強中",
    },
    {
      title: "タイピング技能検定 8級",
      year: 2024,
      month: 7,
      date: 28,
      description: "",
      isValid: 1,
      isPublic: 0,
    },
  ];
  
  // IDが存在しない場合に自動採番
  const certificationsWithIds = certificationsData.map((certification, index) => ({
    ...certification,
    id: certification.id || index + 1, // 既にIDがあればそれを使用、なければ自動採番
  }));
  const sortedCertificationsData = certificationsWithIds.sort((a, b) => b.id - a.id);
  export default sortedCertificationsData;
  