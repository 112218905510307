
import React from 'react';
import certificationsData from '../data/certificationsData';

function Certifications() {
  return (
    <section>
      <div class="w-800">
        <div class="page_header">
          <h2>
            Certifications
            <div class="title_ja">資格・認定</div>
          </h2>
        </div>
        <ul class="certifications grid gap-1rem">
          {certificationsData.filter(certification => certification.isPublic === 1).map((certification) => (
            <li key={certification.id} class={certification.isValid ? '' : 'invalid'}>
              <div className="certification_title">{certification.title} <span className="certification_year">({certification.year})</span></div>
              <p className="certifications_description">{certification.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Certifications;