import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// CSS
import './assets/css/common.css';

import Home from './Home';
import Profile from './Profile';
import Awards from './pages/Awards';
import Certifications from './pages/Certifications';


function App() {
  return (
    <Router basename="/">
      <header>
        <div class="header_contents w-800">
        <a href="/" class="site_title">ささきゆうすけ</a>
        </div>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/awards" element={<Awards />} />
          <Route path="/certifications" element={<Certifications />} />
        </Routes>

      </main>
      <footer>
        <div class="footer_contents w-800">
          <p>&copy;&nbsp;<a href="/">Yusuke Sasaki</a></p>
        </div>
      </footer>
    </Router>
  );
}

export default App;
